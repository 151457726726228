<template>
  <ul>
    <li>Organization: {{ selectedOrg?.name }}</li>
    <li>Number of users: {{ assignedUserIds.length }}</li>
    <li>Number of lessons: {{ assignedLessonIds.length }}</li>
    <li>Due date: {{ selectedAssignmentDateString }}</li>
    <li>{{ selectedResetString }}</li>
  </ul>
</template>
<script lang="ts" setup>
import {
  assignedLessonIds,
  assignedUserIds,
  dueDate,
  resetLearningProgress,
  selectedOrg,
  resetProgressBeforeDate,
} from './newAssignmentsStore';
import { computed } from 'vue';
import { format } from 'date-fns';

const selectedAssignmentDateString = computed(() => {
  return dueDate.value
    ? 'Selected due date ' + format(new Date(dueDate.value), 'dd/MM/yyyy')
    : 'No date selected';
});

const selectedResetString = computed(() => {
  if (resetLearningProgress.value === true) {
    if (resetProgressBeforeDate.value) {
      return (
        'Progress resets for completions before ' +
        format(new Date(resetProgressBeforeDate.value), 'dd/MM/yyyy')
      );
    }
    return 'Progress resets';
  }
  return 'Progress does not reset';
});
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';
</style>
