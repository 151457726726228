import { ref, computed } from 'vue';
import { format } from 'date-fns';
import { components } from '../../../api/learn-backend-rest-schema';

export const selectedOrg = ref<{ name: string; id: string } | null>(null);
export const selectedOrgHaveEntitlement = ref<boolean>(false);

export const assignedUserIds = ref<string[]>([]);
export const assignedLessonIds = ref<string[]>([]);

//export const startDate = ref<number>(new Date().getTime()); TODO: Uncomment when a future startDate is supported
/**
 * @description The due date for the assignment. Type is number or null. Must be any due to bug in ProductCL. Reported here https://snyk.slack.com/archives/C04EDV00NJG/p1710159330358899?thread_ts=1709895354.429619&cid=C04EDV00NJG
 */
export const dueDate = ref<number | any>(null);
export const assignmentDatesSet = ref<boolean>(false);

export const resetLearningProgress = ref<boolean>(false);
export const resetLearningProgressSet = ref<boolean>(false);
export const resetProgressBeforeDate = ref<number | any>(null);

const getFormattedDateForPayload = (date: Date) => {
  return format(date, 'yyyy-MM-dd');
};

export const newAssignmentsStore = () => ({
  isStep1Complete: computed(
    () => !!selectedOrg.value && selectedOrgHaveEntitlement.value,
  ),
  isStep2Complete: computed(() => !!assignedUserIds.value.length),
  isStep3Complete: computed(() => !!assignedLessonIds.value.length),
  isStep4Complete: computed(() => assignmentDatesSet.value === true),
  isStep5Complete: computed(() => resetLearningProgressSet.value === true),
  buildCreateLessonAssignmentsPayload: () => {
    const assignments: components['schemas']['CreateAssignmentsPayload'] = {
      data: [
        {
          attributes: {
            user_ids: assignedUserIds.value,
            lesson_ids: assignedLessonIds.value,
          },
          type: 'lesson_assignment',
        },
      ],
      //start_date: getFormattedDateForPayload(new Date(startDate.value)), TODO: Uncomment when a future startDate is supported
      due_date: dueDate.value
        ? getFormattedDateForPayload(new Date(dueDate.value))
        : undefined,
      reset_learning_progress: resetLearningProgress.value,
      reset_progress_before: resetProgressBeforeDate.value
        ? getFormattedDateForPayload(new Date(resetProgressBeforeDate.value))
        : undefined,
    };
    return assignments;
  },
});

export const resetStore = () => {
  selectedOrg.value = null;
  selectedOrgHaveEntitlement.value = false;
  assignedUserIds.value = [];
  assignedLessonIds.value = [];
  //startDate.value = new Date().getTime(); TODO: Uncomment when a future startDate is supported
  dueDate.value = null;
  assignmentDatesSet.value = false;
  resetLearningProgress.value = false;
  resetLearningProgressSet.value = false;
};
