<template>
  <BetaBanner />
  <main class="new-assignments">
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <h1 class="new-assignments__title">New Assignments</h1>
    <p class="new-assignments__description">
      Create new assignments for users with Snyk accounts
    </p>
    <h2 class="new-assignments__subtitle">Create your assignments</h2>
    <BaseStepper
      class="new-assignments__stepper"
      :steps="steps"
      direction="column"
    >
      <template #1="{ step }">
        <StepWrapper
          :is-active="isStepActive(step)"
          :next-step="nextStepOne"
          :can-progress="isStep1Complete"
        >
          <template v-if="isStep1Complete && !isStepActive(step)" #selection>
            {{ selectedOrg?.name }} selected
          </template>
          <SelectOrgStep :org-id="selectedOrg?.id || props.orgId" />
        </StepWrapper>
      </template>
      <template #2="{ step }">
        <StepWrapper
          :is-active="isStepActive(step)"
          :can-progress="isStep2Complete"
          :previous-step="
            () => {
              itly.assignmentsAreCreated({
                action: 'Previous',
                assignmentsType: 'Lessons',
                creationId: uniqueAnalyticsId,
                eventSource: 'Learn',
                path: '/admin/assignments/new',
                step: 'selectUsers',
                stepValue: assignedUserIds.toString(),
                ...(selectedOrg && { orgPublicId: selectedOrg.id }),
              });
              assignedUserIds = [];
              previousStep();
            }
          "
          :next-step="
            () => {
              if (isStep2Complete) {
                itly.assignmentsAreCreated({
                  action: 'Next',
                  assignmentsType: 'Lessons',
                  creationId: uniqueAnalyticsId,
                  eventSource: 'Learn',
                  path: '/admin/assignments/new',
                  step: 'selectUsers',
                  stepValue: assignedUserIds.toString(),
                  ...(selectedOrg && { orgPublicId: selectedOrg.id }),
                });
                nextStep();
              }
            }
          "
        >
          <template
            v-if="
              isStepActive(step) || (isStep2Complete && !isStepActive(step))
            "
            #selection
          >
            Assigned to {{ assignedUserIds.length }} users. An email will be
            sent with details about the assignments.
          </template>
          <SelectUsersStep />
        </StepWrapper>
      </template>
      <template #3="{ step }">
        <StepWrapper
          :is-active="isStepActive(step)"
          :can-progress="isStep3Complete"
          :previous-step="
            () => {
              itly.assignmentsAreCreated({
                action: 'Previous',
                assignmentsType: 'Lessons',
                creationId: uniqueAnalyticsId,
                eventSource: 'Learn',
                path: '/admin/assignments/new',
                step: 'selectLessons',
                stepValue: assignedLessonIds.toString(),
                ...(selectedOrg && { orgPublicId: selectedOrg.id }),
              });
              assignedLessonIds = [];
              previousStep();
            }
          "
          :next-step="
            () => {
              if (isStep3Complete) {
                itly.assignmentsAreCreated({
                  action: 'Next',
                  assignmentsType: 'Lessons',
                  creationId: uniqueAnalyticsId,
                  eventSource: 'Learn',
                  path: '/admin/assignments/new',
                  step: 'selectLessons',
                  stepValue: assignedLessonIds.toString(),
                  ...(selectedOrg && { orgPublicId: selectedOrg.id }),
                });
                nextStep();
              }
            }
          "
        >
          <template
            v-if="
              isStepActive(step) || (isStep3Complete && !isStepActive(step))
            "
            #selection
            >{{ assignedLessonIds.length }} items selected</template
          >
          <SelectContentStep />
        </StepWrapper>
      </template>
      <template #4="{ step }">
        <StepWrapper
          :is-active="isStepActive(step)"
          :can-progress="true"
          :previous-step="
            () => {
              itly.assignmentsAreCreated({
                action: 'Previous',
                assignmentsType: 'Lessons',
                creationId: uniqueAnalyticsId,
                eventSource: 'Learn',
                path: '/admin/assignments/new',
                step: 'dueDate',
                stepValue: selectedDueDateString,
                ...(selectedOrg && { orgPublicId: selectedOrg.id }),
              });
              //startDate = new Date().getTime(); TODO: Uncomment when a future startDate is supported
              dueDate = undefined;
              assignmentDatesSet = false;
              previousStep();
            }
          "
          :next-step="
            () => {
              itly.assignmentsAreCreated({
                action: 'Next',
                assignmentsType: 'Lessons',
                creationId: uniqueAnalyticsId,
                eventSource: 'Learn',
                path: '/admin/assignments/new',
                step: 'dueDate',
                stepValue: selectedDueDateString,
                ...(selectedOrg && { orgPublicId: selectedOrg.id }),
              });
              assignmentDatesSet = true;
              nextStep();
            }
          "
        >
          <template v-if="isStep4Complete && !isStepActive(step)" #selection>
            {{ selectedDueDateString }}
          </template>
          <SelectAssignmentDateStep />
        </StepWrapper>
      </template>
      <template #5="{ step }">
        <StepWrapper
          :is-active="isStepActive(step)"
          :can-progress="true"
          :previous-step="
            () => {
              itly.assignmentsAreCreated({
                action: 'Previous',
                assignmentsType: 'Lessons',
                creationId: uniqueAnalyticsId,
                eventSource: 'Learn',
                path: '/admin/assignments/new',
                step: 'resetLearningProgress',
                stepValue: JSON.stringify({
                  resetLearningProgress,
                }),
                ...(selectedOrg && { orgPublicId: selectedOrg.id }),
              });
              resetLearningProgressSet = false;
              resetLearningProgress = false;
              previousStep();
            }
          "
          :next-step="
            () => {
              itly.assignmentsAreCreated({
                action: 'Next',
                assignmentsType: 'Lessons',
                creationId: uniqueAnalyticsId,
                eventSource: 'Learn',
                path: '/admin/assignments/new',
                step: 'resetLearningProgress',
                stepValue: JSON.stringify({
                  resetLearningProgress,
                  ...(resetProgressBeforeDate
                    ? { resetProgressBeforeDate }
                    : {}),
                }),
                ...(selectedOrg && { orgPublicId: selectedOrg.id }),
              });
              resetLearningProgressSet = true;
              nextStep();
            }
          "
        >
          <template v-if="isStep5Complete && !isStepActive(step)" #selection>
            {{ selectedResetString }}
          </template>
          <SelectResetLearningProgressStep />
        </StepWrapper>
      </template>
      <template #6="{ step }">
        <StepWrapper
          :is-active="isStepActive(step)"
          :can-progress="true"
          :submit-in-progress="submitInProgress"
          :previous-step="
            () => {
              itly.assignmentsAreCreated({
                action: 'Previous',
                assignmentsType: 'Lessons',
                creationId: uniqueAnalyticsId,
                eventSource: 'Learn',
                path: '/admin/assignments/new',
                step: 'reviewAndSubmit',
                stepValue: JSON.stringify({
                  selectedOrg: selectedOrg!.name,
                  assignedUserIds,
                  assignedLessonIds,
                  dueDate: selectedDueDateString,
                  resetLearningProgress,
                }),
                ...(selectedOrg && { orgPublicId: selectedOrg.id }),
              });
              previousStep();
            }
          "
          :on-submit="
            () => {
              itly.assignmentsAreCreated({
                action: 'Submit',
                assignmentsType: 'Lessons',
                creationId: uniqueAnalyticsId,
                eventSource: 'Learn',
                path: '/admin/assignments/new',
                step: 'reviewAndSubmit',
                stepValue: JSON.stringify({
                  selectedOrg: selectedOrg!.name,
                  assignedUserIds,
                  assignedLessonIds,
                  dueDate: selectedDueDateString,
                  resetLearningProgress,
                }),
                ...(selectedOrg && { orgPublicId: selectedOrg.id }),
              });
              onSubmit();
            }
          "
        >
          <ReviewAndSubmitStep />
        </StepWrapper>
      </template>
    </BaseStepper>
  </main>
</template>

<script lang="ts" setup>
import BaseStepper, {
  Step,
} from '@patchui/productcl/components/BaseStepper/BaseStepper.vue';
import StepWrapper from './StepWrapper.vue';
import { useStepper, isStepActive } from '../../../hooks/useStepper';
import {
  newAssignmentsStore,
  assignedLessonIds,
  assignedUserIds,
  dueDate,
  selectedOrg,
  assignmentDatesSet,
  resetStore,
  resetLearningProgressSet,
  resetLearningProgress,
  resetProgressBeforeDate,
} from './newAssignmentsStore';
import SelectOrgStep from './SelectOrgStep.vue';
import SelectUsersStep from './SelectUsersStep.vue';
import SelectContentStep from './SelectContentStep.vue';
import SelectAssignmentDateStep from './SelectAssignmentDateStep.vue';
import SelectResetLearningProgressStep from './SelectResetLearningProgressStep.vue';

import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs.vue';
import { Breadcrumbs as IBreadCrumbs } from '../../../components/Breadcrumbs/breadcrumbs';
import ReviewAndSubmitStep from './ReviewAndSubmitStep.vue';
import { useHead } from '@vueuse/head';
import { useToast } from '../../../hooks/useToast';
import itly from '../../../lib/analytics/itly';
import { computed, onUnmounted, ref } from 'vue';
import { makeId } from '@snyk/frontend-lib';
import { useRouter } from 'vue-router';
import BetaBanner from '../BetaBanner.vue';
import { createOrgAssignments } from '../../../api/assignments';
import { format } from 'date-fns';
import { limits } from '../../../lib/utils/constants';

const props = defineProps<{
  orgId?: string;
}>();

onUnmounted(() => {
  selectedOrg.value = null;
});

const router = useRouter();
const toast = useToast();

const breadcrumbs: IBreadCrumbs = [
  [
    {
      icon: 'school-outline',
      label: 'Snyk Learn',
      url: '/',
      isActive: true,
    },
  ],
  [
    {
      label: 'Assignments',
      url: '/admin/assignments/',
      isActive: true,
    },
  ],
  [
    {
      label: 'New assignments',
      url: `/admin/assignments/new/${props?.orgId}`,
      isActive: true,
    },
  ],
];

const initialSteps: Step[] = [
  {
    step: 1,
    label: 'Select an organization',
    status: 'active',
  },
  {
    step: 2,
    label: 'Select users',
    status: 'pending',
  },
  {
    step: 3,
    label: 'Select content',
    status: 'pending',
  },
  {
    step: 4,
    label: 'Set due date (Optional)',
    status: 'pending',
  },
  {
    step: 5,
    label: 'Reset user learning progress',
    status: 'pending',
  },
  {
    step: 6,
    label: 'Submit assignments',
    status: 'pending',
  },
];

const { steps, nextStep, previousStep, resetSteps } = useStepper(initialSteps);
const {
  isStep1Complete,
  isStep2Complete,
  isStep3Complete,
  isStep4Complete,
  isStep5Complete,
  buildCreateLessonAssignmentsPayload,
} = newAssignmentsStore();

const selectedDueDateString = computed(() =>
  dueDate.value
    ? 'Selected due date ' + format(new Date(dueDate.value), 'dd/MM/yyyy')
    : 'No date selected',
);

const selectedResetString = computed(() => {
  if (resetLearningProgress.value) {
    if (resetProgressBeforeDate.value) {
      return `Yes, reset the user learning progress before ${format(
        new Date(resetProgressBeforeDate.value),
        'dd/MM/yyyy',
      )}`;
    }
    return 'Yes, reset the user learning progress';
  }
  return 'No, do not reset the user learning progress';
});

const uniqueAnalyticsId: string = makeId(); // Used in analytics to track a single assignment creation session

const submitInProgress = ref(false);

const onSubmit = async () => {
  submitInProgress.value = true;
  const payload = buildCreateLessonAssignmentsPayload();
  try {
    const orgId = selectedOrg.value?.id;
    if (!orgId) throw new Error('No organization selected');
    if (
      assignedUserIds.value.length * assignedLessonIds.value.length >
      limits.ASSIGNMENTS_SIZE
    ) {
      toast.error(
        `A maximum of ${limits.ASSIGNMENTS_SIZE} assignments can be submitted. Lower the number of assignments selected and try again.`,
      );
      submitInProgress.value = false;
      return;
    }
    await createOrgAssignments(orgId, payload);
    toast.info(`Assignments created successfully`);
    resetStore();
    resetSteps();
    await router.push({ path: `/admin/assignments/${orgId}/` });
  } catch (e) {
    toast.error(`Failed to submit assignments`);
    submitInProgress.value = false;
    resetStore();
    resetSteps();
  }
};

useHead({
  title: 'New Assignments | Snyk Learn',
});

const nextStepOne = () => {
  if (isStep1Complete) {
    itly.assignmentsAreCreated({
      action: 'Next',
      assignmentsType: 'Lessons',
      creationId: uniqueAnalyticsId,
      eventSource: 'Learn',
      path: '/admin/assignments/new',
      step: 'selectOrg',
      stepValue: selectedOrg.value ? selectedOrg.value.name : '',
      ...(selectedOrg.value && { orgPublicId: selectedOrg.value.id }),
    });
    nextStep();
  }
};
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.new-assignments {
  max-width: $pageMaxWidth;
  width: 100%;
  margin: 0 auto;
  padding: token('space.xxl') $mobileContainerPadding 0;

  @include media-query(small) {
    padding: token('space.xxl') $containerPadding 0;
    max-width: $pageMaxWidth;
  }
  &__title {
    @include typography('typography.product.heading3');
    color: token('color.ui.heading');
    margin-top: token('space.l');
    @include media-query(large) {
      @include typography('typography.product.heading1');
    }
  }

  &__description {
    @include typography('typography.product.body-small');
    color: token('color.ui.body');
    margin-bottom: token('space.l');
    @include media-query(large) {
      margin-bottom: token('space.xl');
    }
  }

  &__subtitle {
    @include typography('typography.product.heading2');
    color: token('color.ui.heading');
    margin-bottom: token('space.l');
    margin-left: token('space.l');
  }

  &__stepper {
    margin-left: token('space.xl');
    padding-bottom: token('space.layout.xlarge');
    :deep(li) {
      margin: 0;
    }
  }
}
</style>
