<template>
  <div class="reset-step">
    <div class="reset-step__text-block">
      Resetting progress will remove all completion data for the selected
      lessons. This cannot be undone.
    </div>
    <form>
      <BaseRadio
        v-model="resetLearningProgress"
        name="reset-progress"
        class="reset-step__radio-button"
        :value="false"
        label="No, keep existing progress. I want to count historical lesson completions towards this assignment."
        :checked="resetLearningProgress === false"
        @change="resetDate"
      />
      <BaseRadio
        v-model="resetLearningProgress"
        name="reset-progress"
        class="reset-step__radio-button"
        :value="true"
        label="Yes, reset progress, I want all lessons to be taken again for this assignment."
        :checked="resetLearningProgress === true"
      />
      <div v-if="resetLearningProgress" class="reset-step__text-block">
        <br />
        <div class="reset-step__date-picker">
          <BaseDatePicker
            v-model="resetProgressBeforeDate"
            :max-date="new Date()"
            :teleport="true"
            :auto-apply="false"
            placeholder="Select a date"
            label="Keep progress from this date onward (optional)"
            :clearable="true"
            @update:modelValue="handleDateUpdate"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import BaseRadio from '@patchui/productcl/components/BaseRadio/BaseRadio.vue';
import BaseDatePicker from '@patchui/productcl/components/BaseDatePicker/BaseDatePicker.vue';
import {
  resetLearningProgress,
  resetProgressBeforeDate,
} from './newAssignmentsStore';

const handleDateUpdate = (value: number | number[] | undefined): void => {
  if (typeof value === 'number') {
    resetProgressBeforeDate.value = value;
    return;
  }
  resetProgressBeforeDate.value = null;
};

const resetDate = () => {
  if (resetLearningProgress.value === false) {
    resetProgressBeforeDate.value = null;
  }
};
</script>

<style lang="scss" scoped>
@import '~@/utils';
@import '~@/variables';

.reset-step {
  &__text-block {
    font-family: token('typography.family.roboto');
    font-size: token('size.small.font-size');
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.1px;
    text-align: left;
    color: token('color.ui.body');
    padding-bottom: token('space.m');
  }

  &__radio-button:first-child {
    margin-bottom: 16px;
  }
}
</style>
