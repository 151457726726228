import { computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { authenticatedClient } from '../lib/apiClient';
import {
  HiddenApiResponse,
  RestApiResponse,
} from '../lib/utils/type-utilities';

import type { Ref } from 'vue';
import type { components as hiddenComponents } from './learn-backend-hidden-schema';
import type { components as restComponents } from './learn-backend-rest-schema';
import { v4 } from 'uuid';

export type LessonAssignmentDecoratedResource =
  restComponents['schemas']['LessonAssignmentDecoratedResource'];
export type LessonAssignmentRecordResource =
  hiddenComponents['schemas']['LessonAssignmentRecordResource'];

export const listOrgAssignments = async (orgId: string) => {
  const requestUrl = `/rest/orgs/${orgId}/learn/assignments?version=2024-10-15`;
  const response = await authenticatedClient.get<
    RestApiResponse<'listOrgAssignments'>
  >(requestUrl);
  return response.data.data;
};
export const LIST_ORG_ASSIGNMENTS_BASE_KEY = 'listOrgAssignments';
export const useListOrgAssignments = (orgIdRef: Ref<string | undefined>) => {
  const enabled = computed(() => !!orgIdRef.value);
  const query = useQuery({
    queryKey: [LIST_ORG_ASSIGNMENTS_BASE_KEY, orgIdRef],
    enabled,
    queryFn: () => listOrgAssignments(orgIdRef.value as string),
    gcTime: 0,
    retry: false,
  });
  return query;
};

export const deleteOrgAssignments = async (
  orgId: string,
  assignmentIds: string[],
) => {
  const requestUrl = `/rest/orgs/${orgId}/learn/assignments?version=2024-10-15`;
  const body = {
    data: assignmentIds.map((id) => ({ id, type: 'lesson_assignment' })),
  };
  await authenticatedClient.delete<RestApiResponse<'deleteOrgAssignments'>>(
    requestUrl,
    {
      data: body,
    },
  );
};

export const updateOrgAssignments = async (
  orgId: string,
  assignmentIds: string[],
  newDueDate: string,
) => {
  const requestUrl = `/rest/orgs/${orgId}/learn/assignments?version=2024-10-15`;
  const body = {
    id: v4(),
    type: 'assignments_patch',
    attributes: {
      assignments_ids: assignmentIds,
      due_date: newDueDate,
    },
  };
  await authenticatedClient.patch<RestApiResponse<'updateOrgAssignments'>>(
    requestUrl,
    {
      data: body,
    },
  );
};

export const createOrgAssignments = async (
  orgId: string,
  payload: restComponents['schemas']['CreateAssignmentsPayload'],
) => {
  await authenticatedClient.post<RestApiResponse<'createOrgAssignments'>>(
    `/rest/orgs/${orgId}/learn/assignments?version=2024-10-15`,
    payload,
  );
};

export const listMyAssignments = async () => {
  const response = await authenticatedClient.get<
    HiddenApiResponse<'listMyAssignments'>
  >(`/hidden/self/assignments?version=2024-03-03~experimental`);
  return response.data.data;
};

export const useListMyAssignments = () => {
  return useQuery({
    queryKey: ['assignedLessons'],
    queryFn: listMyAssignments,
  });
};
